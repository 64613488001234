<template>
    <div>
        <headers />
        <div class="container mb-30">
            <el-card class="box-card">
                <filters />
                <div class="flex align-items space-between mt-30 mb-20">
                    <div class="orderBy">
                        <el-button v-for="(item, index) in orderByList" :key="index"
                            :type="item.val === orderBy ? 'danger' : 'default'" @click="orderBy = item.val">{{
                                item.name }} <i :class="item.icon"></i>
                        </el-button>
                    </div>
                    <el-form>
                        <el-input placeholder="请输入搜索内容" v-model="searchKey">
                            <template slot="suffix">
                                <el-button type="danger">搜索</el-button>
                            </template>
                        </el-input>
                    </el-form>
                </div>
                <item v-for="(item, index) in bxList" :key="index" :item="item" />
                <div class="text-center mt-20">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                        layout="total, sizes, prev, pager, next, jumper" :total="400">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <footers />
    </div>
</template>

<script>
import headers from "@/components/header.vue"
import footers from "@/components/footer.vue"
import filters from "./components/filters.vue"
import item from "./components/item.vue"


import { getBxList } from "@/api/biaoxun"

export default {
    name: "biaoxun",
    components: {
        headers,
        footers,
        filters,
        item
    },
    data () {
        return {
            orderBy: 1,
            orderByList: [
                {
                    val: 1,
                    name: '降序',
                    icon: 'el-icon-sort-down'
                },
                {
                    val: 2,
                    name: '升序',
                    icon: 'el-icon-sort-up'
                },
            ],
            // 搜索装备名称
            searchKey: '',
            bxList: [],
            currentPage: 1,
        }
    },
    created () {

    },
    mounted () {
        this.searchBx()
    },
    methods: {
        async searchBx () {
            const res = await getBxList()
            this.bxList = res.data.data.list
        },
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`)
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`)
        }
    }
}
</script>

<style lang="less" scoped>
.hd-title {
    text-align: center;
    margin-top: 60px;

    h3 {
        font-size: 28px;
        font-weight: 700;
        color: #2E3033;
    }

    p {
        font-size: 14px;
        color: rgba(46, 48, 51, .6);
        margin-top: 10px;
    }
}

/deep/.el-input__suffix {
    right: 0;
}

.orderBy {
    .el-button {
        border-color: transparent;

        &.el-button--danger:hover {
            color: #fff;
        }

        &:hover {
            color: #000;
            background-color: transparent;
        }
    }
}
</style>