<template>
    <div class="filter">
        <el-row>
            <el-col :span="4">招标单位类别</el-col>
            <el-col :span="20">
                <ul class="filter-lis">
                    <li v-for="item in units" :key="item.value" :class="{ active: item.value === activeUnit }">
                        {{ item.name }}
                    </li>
                </ul>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="4">招投标类型</el-col>
            <el-col :span="20">
                <ul class="filter-lis">
                    <li v-for="item in biddingType" :key="item.value"
                        :class="{ active: item.value === activeBiddingType }">
                        {{ item.name }}
                    </li>
                </ul>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="4">渠道</el-col>
            <el-col :span="20">
                <ul class="filter-lis">
                    <li v-for="item in channels" :key="item.value" :class="{ active: item.value === activeChannel }">
                        {{ item.name }}
                    </li>
                </ul>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="4">发布时间</el-col>
            <el-col :span="20">
                <ul class="filter-lis">
                    <li v-for="item in times" :key="item.value" :class="{ active: item.value === activeTime }">
                        {{ item.name }}
                    </li>
                </ul>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="4">行政地区</el-col>
            <el-col :span="20">
                <div class="p-sm">
                    <el-select v-model="value" placeholder="请选择省">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="请选择市">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value" placeholder="请选择县">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "filters",
    components: {
    },
    data () {
        return {
            // 招标单位类别
            activeUnit: 0,
            units: [
                { name: '全部', value: 0 },
                { name: '应急管理', value: 1 },
                { name: '消防', value: 2 },
                { name: '森林消防', value: 3 },
                { name: '地震', value: 4 },
                { name: '安全生产', value: 5 },
                { name: '其他', value: 6 },
            ],
            // 招投标类型
            activeBiddingType: 0,
            biddingType: [
                { name: '全部', value: 0 },
                { name: '公开招标', value: 1 },
                { name: '询价公告', value: 2 },
                { name: '中标公告', value: 3 },
                { name: '更正公告', value: 4 },
                { name: '邀请公告', value: 5 },
                { name: '成交公告', value: 6 },
                { name: '终止公告', value: 7 },
                { name: '竞争性谈判', value: 8 },
                { name: '竞争性磋商', value: 9 },
                { name: '单一来源', value: 10 },
                { name: '采购意向', value: 11 },
                { name: '其他', value: 12 },
            ],
            // 渠道
            activeChannel: 0,
            channels: [
                { name: '全部', value: 0 },
                { name: '应安网', value: 1 },
                { name: '公共安全装备网', value: 2 },
                { name: '中国政府采购网', value: 3 }
            ],
            // 发布时间
            activeTime: 0,
            times: [
                { name: '全部', value: 0 },
                { name: '今日', value: 1 },
                { name: '近三日', value: 2 },
                { name: '近一周', value: 3 },
                { name: '近一月', value: 4 },
                { name: '近半年', value: 5 },
                { name: '其他指定时间', value: 6 },
            ],

            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: ''

        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.filter {
    font-size: 14px;
    border: 1px solid rgba(112, 112, 112, 0.10);
    background-color: #fff;

    .el-row:not(:last-child) {
        border-bottom: 1px solid rgba(46, 48, 51, .1)
    }

    .el-col-4 {
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: rgba(217, 217, 217, 0.10);
    }

    .el-select+.el-select {
        margin-left: 15px;
    }

    .filter-lis {
        li {
            list-style: none;
            display: inline-block;
            padding: 15px;
            cursor: pointer;

            &:hover,
            &.active {
                color: #EF312A;
            }
        }
    }
}
</style>