<template>
    <div class="biaoxun-item">
        <router-link :to="{ name: 'biaoxunDetails', params: { id: item.id } }">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.description }}</div>
            <div class="info"><span>{{ item.createTime }}</span>|<span>采购人：{{ item.purchaser }}</span>|<span>代理机构：{{
                item.agen
                    }}</span>
            </div>
            <div class="tags">{{ item.tags }}</div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "biaoxunItem",
    components: {
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            // 招标单位类别
            activeUnit: 0,
            units: [
                { name: '全部', value: 0 },
                { name: '应急管理', value: 1 },
                { name: '消防', value: 2 },
                { name: '森林消防', value: 3 },
                { name: '地震', value: 4 },
                { name: '安全生产', value: 5 },
                { name: '其他', value: 6 },
            ],
            // 招投标类型
            activeBiddingType: 0,
            biddingType: [
                { name: '全部', value: 0 },
                { name: '公开招标', value: 1 },
                { name: '询价公告', value: 2 },
                { name: '中标公告', value: 3 },
                { name: '更正公告', value: 4 },
                { name: '邀请公告', value: 5 },
                { name: '成交公告', value: 6 },
                { name: '终止公告', value: 7 },
                { name: '竞争性谈判', value: 8 },
                { name: '竞争性磋商', value: 9 },
                { name: '单一来源', value: 10 },
                { name: '采购意向', value: 11 },
                { name: '其他', value: 12 },
            ],
            // 渠道
            activeChannel: 0,
            channels: [
                { name: '全部', value: 0 },
                { name: '应安网', value: 1 },
                { name: '公共安全装备网', value: 2 },
                { name: '中国政府采购网', value: 3 }
            ],
            // 发布时间
            activeTime: 0,
            times: [
                { name: '全部', value: 0 },
                { name: '今日', value: 1 },
                { name: '近三日', value: 2 },
                { name: '近一周', value: 3 },
                { name: '近一月', value: 4 },
                { name: '近半年', value: 5 },
                { name: '其他指定时间', value: 6 },
            ],

            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: ''

        }
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.biaoxun-item {
    padding: 30px 0;
    border-bottom: 1px solid rgba(46, 48, 51, .1);

    a {
        text-decoration: none;
    }

    .title {
        color: #2E3033;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;

        span {
            color: #EF312A;
        }
    }

    .desc {
        color: rgba(46, 48, 51, .8);
        font-size: 16px;
        margin: 10px 0;

        span {
            color: #EF312A;
        }
    }

    .info {
        margin-bottom: 10px;

        span+span {
            margin-left: 10px;
        }

        span {
            margin-right: 10px;
        }
    }

    .info,
    .tags {
        color: #3A9B18;
        font-size: 14px;
    }
}
</style>